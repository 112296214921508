<template>
  <div class="app-config">
    <a-tabs v-model:activeKey="activeKey" size="small">
      <!-- 不要疑惑，没办法，都TM到了测试阶段又说「公告设置」要挪到「业务管理」下面。草，劳资这么多判断都白写了。 -->
      <!-- 故逻辑均未删除，只注释了template -->
      <!-- <a-tab-pane key="a" tab="公告设置"></a-tab-pane> -->
      <a-tab-pane key="b" tab="App版本设置"></a-tab-pane>
    </a-tabs>
    <!-- 操作 -->
    <div class="action">
      <a-button type="primary" size="small" @click="showAddEditModal">新增{{ isNotice ? '公告' : '版本' }}</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="isNotice ? noticeDataSource : versionDataSource"
      :columns="isNotice ? noticeColumns : versionColumns"
      :pagination="{
        pageSize: isNotice ? noticePageSize : versionPageSize,
        current: isNotice ? noticeCurPage : versionCurPage,
        total: isNotice ? noticeTotal : versionTotal
      }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #noticeIdx="row">
        {{ (noticeCurPage - 1) * noticePageSize + row.index + 1 }}
      </template>
      <template #versionIdx="row">
        {{ (versionCurPage - 1) * versionPageSize + row.index + 1 }}
      </template>
      <template #display="row">
        {{ row.record.display ? '是' : '否' }}
      </template>
      <template #isForceUpdate="row">
        {{ row.record.isForceUpdate ? '是' : '否' }}
      </template>
      <template #noticeAction="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
      <template #versionAction="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
        </div>
      </template>
    </a-table>
  </div>
  <add-edit-modal ref="AddEditModalRef" @addOrUpdateSuccess="search"></add-edit-modal>
</template>

<script>
import { defineComponent, reactive, ref, computed, toRefs, createVNode, onMounted } from 'vue';
import AddEditModal from './AddEditModal';
import { Modal, message } from 'ant-design-vue';
import api from '@/services';

const noticeColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'noticeIdx'
    }
  },
  {
    title: '公告标题',
    dataIndex: 'noticeTitle',
    align: 'center',
    ellipsis: true
  },
  {
    title: '公告内容',
    dataIndex: 'noticeContent',
    align: 'center',
    ellipsis: true
  },
  {
    title: '是否展示',
    dataIndex: 'display',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'display'
    }
  },
  {
    title: '展示截止日',
    dataIndex: 'deadLine',
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'noticeAction'
    }
  }
];

const versionColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'versionIdx'
    }
  },
  {
    title: '版本号',
    dataIndex: 'versionNumber',
    align: 'center',
    ellipsis: true
  },
  {
    title: '更新内容',
    dataIndex: 'updateContent',
    align: 'center',
    ellipsis: true
  },
  {
    title: '是否强制更新',
    dataIndex: 'isForceUpdate',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'isForceUpdate'
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'versionAction'
    }
  }
];
export default defineComponent({
  components: {
    AddEditModal
  },
  setup() {
    const activeKey = ref('b');
    const isEdit = ref(false);

    const AddEditModalRef = ref();

    // notice table 相关变量
    const noticeTableState = reactive({
      noticePageSize: 5,
      noticeCurPage: 1,
      noticeTotal: 0,
      noticeDataSource: []
    });
    // version table 相关变量
    const versionTableState = reactive({
      versionPageSize: 5,
      versionCurPage: 1,
      versionTotal: 0,
      versionDataSource: []
    });

    const isNotice = computed(() => {
      return activeKey.value === 'a';
    });

    onMounted(() => {
      getNoticeList();
      getVersionList();
    });

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditModalRef.value.showModal(isEdit.value, rowData, isNotice);
    };

    // 获取公告设置 table data
    const getNoticeList = async () => {
      let params = {
        curPage: noticeTableState.noticeCurPage,
        pageSize: noticeTableState.noticePageSize
      };
      const { data, success } = await api.getNoticeList(params);
      if (success) {
        noticeTableState.noticeDataSource = data.records;
        noticeTableState.noticeTotal = data.total;
      }
    };

    // 获取版本设置 table data
    const getVersionList = async () => {
      let params = {
        curPage: versionTableState.versionCurPage,
        pageSize: versionTableState.versionPageSize
      };
      console.log(params)
      const { data, success } = await api.getVersionList(params);
      if (success) {
        versionTableState.versionDataSource = data.records;
        versionTableState.versionTotal = data.total;
      }
    };

    const search = val => {
      console.log('val=====', val);
      if (val.value) {
        noticeTableState.noticeCurPage = 1;
        getNoticeList();
      } else {
        versionTableState.versionCurPage = 1;
        getVersionList();
      }
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除该公告吗？`
        ),

        async onOk() {
          let { success } = await api.delAppNoticeById(row.record.id);
          if (success) {
            message.success('删除成功！');
            noticeTableState.noticeCurPage = 1;
            getNoticeList();
          }
        }
      });
    };

    const handlePageChange = page => {
      if (isNotice.value) {
        noticeTableState.noticeCurPage = page.current;
        getNoticeList();
      } else {
        versionTableState.versionCurPage = page.current;
        getVersionList();
      }
    };

    return {
      search,
      del,
      noticeColumns,
      versionColumns,
      activeKey,
      AddEditModalRef,
      showAddEditModal,
      isNotice,
      handlePageChange,
      getVersionList,
      getNoticeList,
      ...toRefs(noticeTableState),
      ...toRefs(versionTableState)
    };
  }
});
</script>

<style lang="less" scoped>
.app-config {
  padding: 15px 20px;
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .status-tag {
        background-color: #4acc90;
        color: #fff;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
          margin-right: 14px;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
